import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

import { inject, observer } from 'mobx-react'
import { withTranslation, Link, i18n } from '../../i18n'
import RawPagesList from '../../constants/pages'

import { ContainerDiv, MenuItemA, BackgroundImg } from './styles'
import LanguageSwitch from './LanguageSwitch'
import { isOnPage } from './utils'

import { pageListFilterByLang } from '../../utils/pageList'
@inject('store')
@observer
class Header extends Component {
  static propTypes = {
    router: PropTypes.instanceOf(Object).isRequired,
    t: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  }

  openModal = () => {
    const {
      t,
      store: { toggleLoginModal }
    } = this.props
    toggleLoginModal(true, t('auth:REGISTER_MESSAGE'))
  }

  openSearch = () => {
    const {
      store: { toggleSearchModal }
    } = this.props
    toggleSearchModal(true)
  }

  render() {
    const {
      t,
      router,
      store: { user }
    } = this.props
    const { pathname } = router
    const PagesList = RawPagesList.map((page) => {
      const newPage = page
      if (page.link.indexOf('/program') !== -1) {
        // eslint-disable-next-line
        newPage.link = user.id && user.performances.length > 0 ? `/program?programType=profile&id=${user.id}` : '/program'
        newPage.as = user.id && user.performances.length > 0 ? `/program/profile/${user.id}` : undefined
      }
      return newPage
    })

    if (this.currentLanguage !== i18n.language) this.currentLanguage = i18n.language

    return (
      <ContainerDiv>
        <BackgroundImg
          src="/static/images/footer.png"
          srcSet="/static/images/footer.png 1440w, /static/images/footer@2x.png 2880w"
        />
        <div className="headerContent">
          {PagesList.filter(page => pageListFilterByLang(this.currentLanguage,page)).map(page => (
             <Link key={page.link} href={page.link} as={page.as || page.link} passHref>
               <MenuItemA
                 selected={isOnPage(page.link, pathname)}
               >
                 {t(page.title)}
               </MenuItemA>
             </Link>
          ))}
          <MenuItemA href="https://crm.uralmusicnight.ru" target="_blank">
            {t('submit-application')}
          </MenuItemA>
          <div style={{ flex: 1 }} />
          <LanguageSwitch />
          <div style={{ flex: 0.2 }} />
          {router.route !== '/search' && (
            <img onClick={this.openSearch} className="searchIcon" src="/static/images/searcher.svg" alt="search" />
          )}
          {!user.avatar ? (
            <img
              className="profileIcon"
              onClick={this.openModal}
              alt="profile"
              src={user.token ? '/static/images/profile-icon-active.svg' : '/static/images/profile-icon.svg'}
            />
          ) : (
            <img className="profileIcon avatar" onClick={this.openModal} src={user.avatar} alt="profile" />
          )}
        </div>
      </ContainerDiv>
    )
  }
}

const HeaderWithRouter = withRouter(Header)
export default withTranslation(['common', 'auth'])(HeaderWithRouter)
