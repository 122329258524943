/* eslint-disable no-console */
/* eslint-disable max-len */
import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactSVG from 'react-svg'
import { css } from 'styled-components'
import Cookies from 'cookies-js'

import { inject, observer } from 'mobx-react'
import Layout from '../components/Layout'
import Timer from '../components/Timer'
import Button from '../components/Button'
import Footer from '../components/Footer'
import GridItem from '../components/GridItem'
import UmnServiceLink from '../components/UmnServiceLink'

import { fetchMainPageData } from '../api/utils'
import { fetchButtonsData } from '../api/common'
import { favoriteRoute } from '../api/routes'
import { auth } from '../api/authorization'

import { withTranslation, Link, i18n, Router } from '../i18n'
import {
  MainInfoDiv,
  CountersDiv,
  SocialLink,
  SocialIcon,
  TimerContainer,
  ContentContainer,
  MobileAppContainer,
  SideProjectsContainer,
  RoutesContent,
  StreamContainer,
  SubscribeContainer,
  ButtonLinksWrapper
} from '../styles/indexStyles'
import { LocationPin } from '../public/static/svgs/LocationPin'
import RouteCard from '../components/RouteCard'
import Loading from '../components/Loading'
import SubscribeForm from '../components/SubscribeForm'

@inject('store')
@observer
class About extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired,
    currentLanguage: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    access_token: PropTypes.string.isRequired,
    loginEmail: PropTypes.string,
    head: PropTypes.string
  }

  static defaultProps = {
    loginEmail: undefined,
    head: undefined
  }

  state = {
    headliners: [],
    places: [],
    isTablet: false,
    isMobile: false,
    showTimerBlock: true,
    durationForTimer: undefined,
    timerExpired: true,
    text: '',
    showVideo: false,
    video: undefined,
    isLoading: false,
    coverImageUrl: 'main-cover_2021',
    buttons: []
  }

  authBySocialNetwork = (network, access_token, code) => {
    const { t } = this.props
    let credentials = {
      access_token
    }
    if (code !== undefined) {
      credentials = {
        code
      }
    }
    auth(credentials, network)
      .then((res) => {
        Cookies.set('userToken', res.token)
        this.props.store.setToken(res.token)
        this.props.store.updateName()
        this.props.store.toggleSuccessModal(true, t('auth:SUCCESS_LOGIN'))
      })
      .catch((er) => {
        console.error(er.message)
      })
  }

  componentDidMount() {
    this.fetchData()
    this.fetchButtons()
    const {
      state,
      access_token,
      store: { user, setLoginEmail },
      code,
      loginEmail
    } = this.props

    const { asPath } = Router

    if (loginEmail) {
      setLoginEmail(loginEmail)
    }

    if (user.token) return

    let parsed_access_token = access_token
    let parsed_state = state
    if (state === '' || access_token === '') {
      const accessTokenIndex = asPath.indexOf('access_token=')
      if (accessTokenIndex !== -1) {
        parsed_access_token = asPath.slice(
          accessTokenIndex + 13,
          asPath.indexOf('&')
        )
      }
      const stateIndex = asPath.indexOf('state=')
      if (stateIndex !== -1) {
        parsed_state = asPath.slice(stateIndex + 6, stateIndex + 8)
      }
    }

    if (parsed_state === 'vk' && code !== '') {
      this.authBySocialNetwork('vkontakte/web', null, code)
    }
    // if (parsed_state === 'fb' && parsed_access_token !== '') {
    //   this.authBySocialNetwork('facebook', parsed_access_token)
    // }
  }

  async fetchButtons() {
    const { currentLanguage } = this.props
    const language = i18n.language || currentLanguage

    const { buttons } = await fetchButtonsData(language)
    this.setState({ buttons })
  }
  async fetchData() {
    const { currentLanguage, head } = this.props
    const language = i18n.language || currentLanguage

    this.setState({ isLoading: true })
    const {
      places,
      partner_routes: routes,
      headliners,
      text,
      timer,
      video,
      header_image: headerImage,
      show_video: showVideo
    } = await fetchMainPageData(head, language)

    this.setState({ isLoading: false })

    this.props.store.setPartnerRoutes(routes || [])

    const timerDuration = moment.duration(moment(timer).diff(moment()))
    this.setState({
      text,
      showTimerBlock: !!timer,
      timerExpired: timerDuration.asMilliseconds() <= 0,
      durationForTimer: timerDuration,
      showVideo,
      video,
      coverImageUrl: headerImage,
      headliners: headliners || [],
      places: places || []
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.fetchData()
      this.fetchButtons()
    }
  }

  static async getInitialProps({ req, query }) {
    const currentLanguage = req ? req.language : i18n.language
    return {
      currentLanguage,
      namespacesRequired: [
        'about',
        'program',
        'common',
        'auth',
        'contacts',
        'reset-password'
      ],
      state: query.state || '',
      code: query.code || '',
      access_token: query.access_token || '',
      loginEmail: query.loginEmail,
      head: query.head || undefined
    }
  }

  changeWindowWidth = (deviceType) => {
    this.setState({
      isMobile: deviceType === 'mobile',
      isTablet: deviceType === 'tablet'
    })
  }

  render() {
    const {
      t,
      store: {
        user: { token },
        partnerRoutes,
        updateRoute,
        toggleLoginModal
      },
      head,
      currentLanguage
    } = this.props

    const {
      isTablet,
      isMobile,
      headliners,
      places,
      video,
      showVideo,
      coverImageUrl,
      text,
      durationForTimer,
      showTimerBlock,
      timerExpired,
      isLoading,
      buttons
    } = this.state

    const language = i18n.language || currentLanguage

    let linkSVGWidth = '210px'
    if (isTablet) linkSVGWidth = '125px'
    else if (isMobile) linkSVGWidth = '160px'

    return (
      <Layout
        meta={{ title: t('common:PAGE_MAIN_SEO_TITLE'), concatName: false }}
        coverImageUrl={coverImageUrl}
        isIndexPage={showVideo}
        showVideo={showVideo}
        video={video}
        changeWindowWidth={this.changeWindowWidth}
        currentLanguage={currentLanguage}
      >
        {/* <CountersDiv>
          <Link href="/artists">
            <a href="/artists" className="counterContainer">
              <h2>1500</h2>
              <span>{t('about:COUNTER_ARTISTS')}</span>
            </a>
          </Link>
          <Link href="/places">
            <a href="/places" className="counterContainer">
              <h2>50</h2>
              <span>{t('about:COUNTER_PLACES')}</span>
            </a>
          </Link>
          <Link href="/broadcast">
            <a className="counterContainer">
              <h2>11</h2>
              <span>{t('about:COUNTER_BROADCAST')}</span>
            </a>
          </Link>
        </CountersDiv> */}

        <ButtonLinksWrapper>
          {buttons.map((button) => (
            <UmnServiceLink button={button} key={button.id} />
          ))}
        </ButtonLinksWrapper>

        <MainInfoDiv>
          <div className="content">
            {/* <h1 className="where">{t('about:WHERE')}</h1> */}
            <h2 className="when">{t('about:WHEN')}</h2>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: text || t('about:DESCRIPTION')
              }}
            />
            <div className="socialButtons">
              <SocialLink
                href="https://vk.com/uralmusicnight"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src="/static/images/vk-30-black.svg" />
              </SocialLink>
              {/* <SocialLink
                href="https://www.facebook.com/uralmusicnight"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src="/static/images/fb-30-black.svg" />
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/uralmusicnight/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src="/static/images/inst-30-black.svg" />
              </SocialLink> */}
              <SocialLink
                href="https://t.me/uralmusicnight"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src="/static/images/tel-30-black.svg" />
              </SocialLink>
              <SocialLink
                href={t('common:ios-link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src="/static/images/apple.svg" />
              </SocialLink>
              <SocialLink
                href={t('common:android-link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src="/static/images/google.svg" />
              </SocialLink>
            </div>
          </div>
        </MainInfoDiv>

        {showTimerBlock && (
          <>
            {!timerExpired ? (
              <TimerContainer>
                <div className="nextFestText">{t('about:next-fest')}</div>
                <Timer
                  duration={durationForTimer}
                  onExpire={() => {
                    this.setState({ timerExpired: true })
                  }}
                />
              </TimerContainer>
            ) : (
              <StreamContainer image="static/images/index-stream-background.jpg">
                <img
                  src="static/images/stream-background.png"
                  alt="stream tear"
                  className="topStreamTear"
                />
                <img
                  src="static/images/stream-background.png"
                  alt="stream tear"
                  className="bottomStreamTear"
                />
                <span className="streamDescription">
                  {t('common:watch_live')}
                </span>
                <Button link="/broadcast">{t('common:watch')}</Button>
              </StreamContainer>
            )}
          </>
        )}

        {/* <StreamContainer image="static/images/index-stream-background.jpg">
          <img
            src="static/images/stream-background.png"
            alt="stream tear"
            className="topStreamTear"
          />
          <img
            src="static/images/stream-background.png"
            alt="stream tear"
            className="bottomStreamTear"
          />
          <span className="streamDescription">
            {t('common:watch_live')}
          </span>
          <Button link="/broadcast">{t('common:watch')}</Button>
        </StreamContainer> */}

        {isLoading ? (
          <Loading />
        ) : (
          <>
            {headliners.length > 0 && (
              <ContentContainer
                ref={(ref) => {
                  if (ref && head && !this.scrolled) {
                    ref.scrollIntoView()
                    this.scrolled = true
                  }
                }}
                artists
              >
                <div className="content">
                  <h2>{`2500 ${t('about:COUNTER_ARTISTS')}`}</h2>
                  <div className="cards">
                    {headliners.map((artist) => (
                      <GridItem
                        key={artist.id}
                        className="card"
                        item={{ artist }}
                        isArtist
                      />
                    ))}
                  </div>
                  <Button link="/artists">{t('about:all_artists')}</Button>
                </div>
              </ContentContainer>
            )}
          </>
        )}

        {isLoading ? (
          <Loading />
        ) : (
          <>
            {places.length > 0 && (
              <>
                <ContentContainer>
                  <div className="content">
                    <h2>{`100 ${t('about:COUNTER_PLACES')}`}</h2>
                    <div className="cards">
                      {places.length === 0 ? (
                        <Loading />
                      ) : (
                        places.map((place) => (
                          <GridItem
                            key={place.id}
                            className="card"
                            item={places}
                          />
                        ))
                      )}
                    </div>
                    <Button link="/places">{t('about:all_places')}</Button>
                  </div>
                </ContentContainer>
              </>
            )}
          </>
        )}


        {/* back partner routes if needed */}
        
        {/* {isLoading ? (
          <Loading />
        ) : (
          <>
            {partnerRoutes.length > 0 && (
              <>
                <ContentContainer>
                  <RoutesContent>
                    <div className="mapContainer">
                      <div className="mapBlurred">
                        <div className="pathComp">
                          <div className="pathContainer">
                            <LocationPin
                              fill="#7697da"
                              className="locationPinUp"
                            />
                            <LocationPin
                              fill="#933ec5"
                              className="locationPinDown"
                            />
                            <img
                              src="/static/images/path-2.svg"
                              alt=""
                              className="pathImg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="routeControls">
                      <h2 className="uppercase">{t('about:PERSONAL_ROUTE')}</h2>
                      <Button link="/program">{t('about:build_path')}</Button>
                    </div>
                  </RoutesContent>
                </ContentContainer>

                <ContentContainer>
                  <div className="content">
                    <h2 className="uppercase">
                      {t('program:RECOMMENDED_ROUTES')}
                    </h2>
                    <div className="routeCards">
                      {
                        // partnerRoutes.length > 0 ?
                        partnerRoutes.map((route) => (
                          <RouteCard
                            key={route.id}
                            route={route}
                            className="routeCardContainer"
                            styles={css`
                              :not(:last-child) {
                                margin-right: 3.55%;
                              }
                              @media (max-width: 750px) {
                                :not(:last-child) {
                                  margin-right: 0;
                                }
                                :nth-child(2n) {
                                  margin-left: 3.55%;
                                }
                                :nth-child(1n + 3) {
                                  margin-top: 5.55%;
                                }
                              }
                            `}
                            onFavorite={() => {
                              if (!token) {
                                toggleLoginModal(
                                  true,
                                  this.props.t('common:make_an_account')
                                )
                              } else {
                                favoriteRoute(
                                  route.id,
                                  'partner_routes',
                                  undefined,
                                  token,
                                  route.favorited
                                )
                                  .then(() => {
                                    updateRoute(route.id)
                                  })
                                  .catch((er) => {
                                    console.error('error', er)
                                  })
                              }
                            }}
                          />
                        ))
                        // : (
                        //   <div className="noRoutesYet">{t('common:no_routes_yet')}</div>
                        //   )
                      }
                    </div>
                    <Button link="/program">{t('about:all_routes')}</Button>
                  </div>
                </ContentContainer>
              </>
            )}
          </>
        )} */}

        <MobileAppContainer>
          <div className="content">
            <div className="description">
              <div className="title">{t('about:app_title')}</div>
              <div className="slug">{t('about:app_slug')}</div>
              <div className="text">{t('about:app_text')}</div>
              <div className="app-links">
                <a
                  href={t('common:ios-link')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ReactSVG
                    svgStyle={{ width: linkSVGWidth }}
                    className="link"
                    src={
                      language === 'ru'
                        ? '/static/images/app-store.svg'
                        : '/static/images/app-store-en.svg'
                    }
                  />
                </a>
                <a
                  href={t('common:android-link')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ReactSVG
                    svgStyle={{ width: linkSVGWidth }}
                    className="link"
                    src={
                      language === 'ru'
                        ? '/static/images/google-play.svg'
                        : '/static/images/google-play-en.svg'
                    }
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="magazine">
            <img
              alt=""
              className="ihponeImg"
              src="/static/images/iphone.png"
              srcSet="/static/images/iphone.png 1440w, /static/images/iphone@2x.png 2880w"
            />
            <img
              alt=""
              className="iphone2Img"
              src="/static/images/iphone-2.png"
              srcSet="/static/images/iphone-2.png 1440w, /static/images/iphone-2@2x.png 2880w"
            />
            <img
              alt=""
              className="iphone3Img"
              src="/static/images/iphone-3.png"
              srcSet="/static/images/iphone-3.png 1440w, /static/images/iphone-3@2x.png 2880w"
            />
            <img
              alt=""
              className="photosImg"
              src="/static/images/photos.png"
              srcSet="/static/images/photos.png 1440w, /static/images/photos@2x.png 2880w"
            />
            <div className="app-links-mobile">
              <a
                href={t('common:ios-link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG
                  svgStyle={{ width: linkSVGWidth }}
                  className="link"
                  src={
                    language === 'ru'
                      ? '/static/images/app-store.svg'
                      : '/static/images/app-store-en.svg'
                  }
                />
              </a>
              <a
                href={t('common:android-link')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG
                  svgStyle={{ width: linkSVGWidth }}
                  className="link"
                  src={
                    language === 'ru'
                      ? '/static/images/google-play.svg'
                      : '/static/images/google-play-en.svg'
                  }
                />
              </a>
            </div>
            <img
              alt=""
              className="whiteGapImgTop"
              src="/static/images/white-gap-left.png"
              srcSet="/static/images/white-gap-left.png 1440w, /static/images/white-gap-left@2x.png 2880w"
            />
            <div className="content">
              <img
                alt=""
                className="umm"
                src="/static/images/umm.png"
                srcSet="/static/images/umm.png 1440w, /static/images/umm@2x.png 2880w"
              />
              <div className="text">{t('about:magazine_text')}</div>
              <Button href="/mag">{t('about:read')}</Button>
            </div>
            <img
              alt=""
              className="whiteGapImgBottom"
              src="/static/images/white-gap-left.png"
              srcSet="/static/images/white-gap-left.png 1440w, /static/images/white-gap-left@2x.png 2880w"
            />
          </div>
        </MobileAppContainer>

        <SubscribeContainer>
          <SubscribeForm />
        </SubscribeContainer>

        <SideProjectsContainer>
          <div className="title">{t('about:side_projects_title')}</div>
          <div className="content">
            {/* <a
              className="project"
              href="https://vk.com/uralmusicschool"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="umm"
                src="/static/images/ums.png"
                srcSet="/static/images/ums.png 1440w, /static/images/ums@2x.png 2880w"
              />
              <div className="text">{t('about:ums_text')}</div>
            </a> */}
            <a
              className="project"
              href="http://umvcrew.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="umm"
                src="/static/images/umvc-logo.png"
                srcSet="/static/images/umvc-logo.png 1440w, /static/images/umvc-logo@2x.png 2880w"
              />
              <div className="text">{t('about:umvc_text')}</div>
            </a>
            {/* <a
              className="project"
              href="http://umusicu.tilda.ws/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="longerImage">
                <img alt="" className="umm" src="/static/images/umu-logo.svg" />
              </div>
              <div className="text">{t('about:umu_text')}</div>
            </a> */}
            <a
              className="project"
              href="http://uralmusiccamp.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="umm"
                src="/static/images/umc-logo-2020.png"
              />
              <div className="text">{t('about:umc_text')}</div>
            </a>

            <a
              className="project"
              href="http://gikkonfest.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="umm"
                src="/static/images/geekcon-logo.png"
                // srcSet="/static/images/geekcon-logo.png 1440w, /static/images/umvc-logo@2x.png 2880w"
              />
              <div className="text">{t('about:geekcon_text')}</div>
            </a>
            <a
              className="project"
              href="https://vk.com/linearubrafest"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="umm"
                src="/static/images/redline-logo.png"
                // srcSet="/static/images/geekcon-logo.png 1440w, /static/images/umvc-logo@2x.png 2880w"
              />
              <div className="text">{t('about:redline_text')}</div>
            </a>
            <a
              className="project"
              href="https://dikorosyfest.ru/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="umm"
                src="/static/images/festival-fairy-tails-logo.png"
                // srcSet="/static/images/geekcon-logo.png 1440w, /static/images/umvc-logo@2x.png 2880w"
              />
              <div className="text">{t('about:festival_fairy_tails_text')}</div>
            </a>
            <a
              className="project"
              href="http://newopen.su/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="longerImage">
              <img
                alt=""
                className="umm"
                src="/static/images/new-open-logo.svg"
              />
              </div>
             
              <div className="text">{t('about:new_open_text')}</div>
            </a>
          </div>
        </SideProjectsContainer>

        <Footer whiteStyle={!isMobile} />
      </Layout>
    )
  }
}

export default withTranslation(['about', 'program', 'common', 'auth'])(About)
