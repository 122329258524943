import React from 'react'
import Sidebar from 'react-sidebar'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { inject, observer } from 'mobx-react'
import { withTranslation, Link, i18n } from '../../i18n'
import PagesList from '../../constants/pages'
import { isOnPage } from '../Header/utils'
import {
  SideBarContainer,
  Menu,
  Button,
  LinkTextA,
  Language,
  Content
} from './styles'

import { fetchButtonsData } from '../../api/common'

import { pageListFilterByLang } from '../../utils/pageList'
@inject('store')
@observer
class MobileMenu extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    router: PropTypes.instanceOf(Object).isRequired,
    currentLanguage: PropTypes.string.isRequired,
    isTablet: PropTypes.bool.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  }
  componentDidMount() {
    this.fetchButtons()
  }

  state = {
    sidebarOpen: false,
    buttons: []
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open })
  }

  changeLanguage = () => {
    const language = i18n.language || this.props.currentLanguage
    if (language === 'en') i18n.changeLanguage('ru')
    else i18n.changeLanguage('en')
  }

  openModal = () => {
    const {
      t,
      store: { toggleLoginModal }
    } = this.props
    toggleLoginModal(true, t('auth:REGISTER_MESSAGE'))
    this.onSetSidebarOpen(false)
  }

  openSearch = () => {
    const {
      store: { toggleSearchModal }
    } = this.props
    toggleSearchModal(true)
  }

  async fetchButtons() {
    const { currentLanguage } = this.props
    const language = i18n.language || currentLanguage

    const { buttons } = await fetchButtonsData(language)
    this.setState({ buttons })
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.fetchButtons()
    }
  }

  render() {
    const {
      t,
      children,
      router,
      currentLanguage,
      isTablet,
      store: { user }
    } = this.props

    const { buttons, sidebarOpen } = this.state

    const language = i18n.language || currentLanguage

    return (
      <Sidebar
        contentId="sidebarContent"
        sidebar={
          <SideBarContainer>
            <Menu>
              <div className="topButtons">
                {!user.avatar ? (
                  <img
                    className="profileIcon"
                    onClick={this.openModal}
                    alt="profile"
                    src={
                      user.token
                        ? '/static/images/profile-icon-active.svg'
                        : '/static/images/profile-icon-black.svg'
                    }
                  />
                ) : (
                  <img
                    className="profileIcon avatar"
                    onClick={this.openModal}
                    src={user.avatar}
                    alt="profile"
                  />
                )}
                <img
                  onClick={this.openSearch}
                  className="searchIcon"
                  src="/static/images/searcher-black.svg"
                  alt="search"
                />
                <Button
                  className="closeIcon"
                  src="/static/images/cross.svg"
                  onClick={() => this.onSetSidebarOpen(false)}
                />
              </div>

              {PagesList.filter((page) =>
                pageListFilterByLang(language, page)
              ).map((page) => (
                <Link
                  key={page.link}
                  href={page.link}
                  as={page.as || page.link}
                  passHref
                >
                  <LinkTextA
                    selected={isOnPage(page.link, router.pathname)}
                    tabIndex={-1}
                  >
                    {t(page.title)}
                  </LinkTextA>
                </Link>
              ))}

              <LinkTextA href="https://crm.uralmusicnight.ru" target="_blank">
                {t('submit-application')}
              </LinkTextA>
              
              {buttons.map((button) => (
                <LinkTextA href={button.link} target="_blank" key={button.id}>
                  {button.title}
                </LinkTextA>
              ))}
            </Menu>

            <Language onClick={this.changeLanguage}>
              {language === 'en' ? 'RU ' : 'EN '}
              <span className="full">{`(${t('language')})`}</span>
            </Language>
          </SideBarContainer>
        }
        open={sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight
        styles={{
          sidebar: {
            zIndex: '100',
            background: '#fafafa',
            width: isTablet ? '50vw' : '70vw'
          },
          overlay: {
            zIndex: 3
          }
        }}
      >
        <Content>
          <div className="mobileHeader" id="mobileHeader">
            <Button
              src="/static/images/group-2.svg"
              onClick={() => this.onSetSidebarOpen(true)}
            />
          </div>
          {children}
        </Content>
      </Sidebar>
    )
  }
}

const MobileMenuWithRouter = withRouter(MobileMenu)
export default withTranslation(['common', 'auth'])(MobileMenuWithRouter)
